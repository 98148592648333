* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.App {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}


header, footer {
    display: block;
}

footer {
    position: sticky;
    z-index: 5;
    bottom: 0;
    width: 100%;
    display: block;
}

footer strong {
    display: block;
    color: #f5f5f5;
    font-size: 0.8rem;
    margin: auto;
    padding-left: 0.5rem;
    text-align: center;
}

main {
    flex: 1;
    display: flex;
    flex-direction: row;
}

main div {
    margin: auto;
}

div.clouds {

}

div.clouds div img.img2 {
    width: 35vw;
}

div.clouds div img.img1 {
    width: 70vw;
}

canvas {
    background-color: #050505;
}

header {
    display: flex;
    flex-direction: row;
}

header span {
    margin: auto;
    display: block;
    padding: 1rem;
    font: 2rem bold;
    cursor: pointer;
}

span.p {
    border: 1px solid #cacaca;
}

pre {
    padding: 2rem;
    text-align: left;
}

body, #root {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

#root {
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.three {
    z-index: 1;
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
}

nav {
    position: absolute;
    z-index: 5;
}

nav a {
    padding: 0.6rem;
    color: #cacaca;
    font-size: 0.8rem;
    cursor: pointer;
    display: block;
}

nav a:hover {
    color: #050505;
    background-color: #cacaca;
}

nav ul {
    padding: 0.4rem;
    margin: 0;
}

nav ul li {
    border: 1px solid #cacaca;
    margin: 0.4rem;
    list-style: none;
    display: flex;
    text-align: center;
    color: #cacaca;
}

.modal {
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    backdrop-filter: blur(5px);
    color: #cacaca;
}

.modal-content {
    max-width: 40rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    height: 100%;
}

.modal-close {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    margin-left: auto;
    display: block;
}

h1 {
    font-size: 1.6rem;
    padding: 0;
    margin: 0;
}

.modal-main {
    flex: 1;
    position: relative;
    overflow: auto;
}

.modal-content aside {
    padding-bottom: 1rem;
}

img.fpu-logo {
    display: block;
    margin: auto;
    width: 8rem;
    height: auto;
}

a {
    color: #fff;
    text-decoration: underline;
}

iframe.fb {
    display: block;
    padding: 0;
    margin: auto;
    width: 500px;
    border: none;
    height: 56rem;
}

form {
    display: block;
    padding: 1rem;
}

input {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    padding: 0;
    margin: 0;
    display: block;
    width: 100%;
    background-color: #000;
    color: #cacaca;
    border: 1px solid #fff;
    padding: 1rem;
}

ul.chat {
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    list-style: none;
}

ul.chat li {
    padding: 1rem;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

ul.chat li strong {
    margin-right: 0.5rem;
}
